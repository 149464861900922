<template>
  <div>
    <b-form-group label="Title" label-for="title">
      <b-form-input v-model="item.title[title_lang]" />
      <LangOptions v-model="title_lang" :options="languages" />
    </b-form-group>
    <b-form-group label="Description" label-for="description-en">
      <b-form-textarea v-model="item.description[description_lang]" rows="3" />
      <LangOptions v-model="description_lang" :options="languages" />
    </b-form-group>
    <b-form-group class="mt-5">
      <template #label>
        Link al contenuto video formativo
        <InfoPopover class="ml-1" text="Embed video: copy and paste here the embed code from the original
              video platform. The content will be presented inside the app. Link to external resource: Insert the link to redirect to an external
              resource. You can upload a preview image to be shown in the app" />
      </template>
      <b-form-radio-group v-model="item['video_type_contenuto_formativo']" :options="video_type_options" class="mb-3">
      </b-form-radio-group>
      <div v-if="item['video_type_contenuto_formativo'] == 1">
        <label>Embed code of the video from the service you are using</label>
        <b-form-textarea v-model="item['embed_contenuto_formativo']" rows="3"></b-form-textarea>
      </div>
      <div v-if="item['video_type_contenuto_formativo'] == 2">
        <label>Video link</label>
        <b-form-input v-model="item['link_contenuto_formativo']" />
        <label class="mt-3">Video placeholder image</label>
        <b-form-file class="drop-file" v-model="video_cover" drop-placeholder="Drop file here..."
          accept="image/jpeg, image/png, image/gif">
          <template #placeholder>
            <img v-if="item.cover_contenuto_formativo.trim() != ''" :src="item.cover_contenuto_formativo"
              class="img-fluid uploader_image_preview" />
            <div v-else class="my-4 text-center">
              <p class="h1 mb-1">
                <b-icon-cloud-arrow-up-fill />
              </p>
              <p>
                Choose a file or drop it here <br />
                (.jpg, .png, .gif)
              </p>
            </div>
          </template>
          <template slot="file-name">
            <img :src="video_cover_url" class="img-fluid uploader_image_preview" />
          </template>
        </b-form-file>
      </div>
    </b-form-group>
    <b-form-checkbox v-model="item['sessione_live']" class="mt-5">
      Streaming classrom video link
      <InfoPopover class="ml-1" text="Insert here the link to reach the online event" />
    </b-form-checkbox>
    <div v-if="item['sessione_live']" class="p-4 bg-gray mt-3">
      <b-row>
        <b-col lg="9">
          <b-form-group>
            <template #label>
              Link a Zoom, Webex o altra classroom live
            </template>
            <b-form-input v-model="item['link_sessione_live']" />
          </b-form-group>
          <b-form-group label="Data e ora">
            <!-- <b-form-input v-model="tmpDate" :type="date"></b-form-input>
            <b-form-input v-model="tmpTime" :type="time"></b-form-input> -->
            <datetime v-model="tmpDatetime" format="YYYY-MM-DD H:i:s"></datetime>
          </b-form-group>
          <b-form-checkbox v-model="item['recorded_video']" class="mt-5">
            <div>
              Add Recordered classrom video link
              <InfoPopover class="ml-1" text="Inser here link to previous events" />
            </div>
            <b-form-input v-if="item['recorded_video']" v-model="item['recorded_video_link']" class="mt-2" />
          </b-form-checkbox>
          <b-button class="mt-5" @click="showRegister = true">Live class attendance register</b-button>
        </b-col>
      </b-row>
    </div>
    <div class="mt-5 mb-5">
      <b-button v-if="isLoading" squared variant="primary" size="lg" class="big-button" disabled>
        <b-spinner small type="grow"></b-spinner> Loading...
      </b-button>
      <b-button v-else squared variant="primary" size="lg" class="big-button" @click="saveItem">Save Details</b-button>
    </div>
    <b-modal v-model="showRegister" @show="loadUser" @hidden="user = []" @ok="saveUser" size="md"
      title="Live class attendance register">
      <div class="register-wrapper">
        <div class="container-fluid">
          <div v-for="user in users" :key="user.id" class="row align-items-center py-3">
            <div class="col-8 d-flex align-items-center">
              <b-form-checkbox v-model="user.present" switch class="mr-2">
              </b-form-checkbox>
              <b-avatar square :src="user.avatar_url" size="3rem" class="mr-3"></b-avatar>
              <span><strong>{{ user.nome }} {{ user.cognome }}</strong></span>
            </div>
            <div class="col-4 px-2 text-center">
              <b-badge v-if="user.present" pill variant="success">Present</b-badge>
              <b-badge v-else pill variant="danger">Not present</b-badge>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import datetime from "vuejs-datetimepicker";
import AxiosService from "@/axiosServices/AxiosService";
export default {
  components: {
    datetime,
  },
  props: ["details", "languages", "default_lang", "isLoading"],
  data() {
    return {
      title_lang: null,
      description_lang: null,
      item: {},
      showRegister: false,

      video_type_options: [
        { value: 1, text: "Embed video" },
        { value: 2, text: "Link to external resource" },
      ],
      video_cover: null,

      users: [],
    };
  },
  service: null,
  created() {
    this.title_lang = this.default_lang.value;
    this.description_lang = this.default_lang.value;
    this.item = this.details;
    this.service = new AxiosService("ClassRoom");
  },
  methods: {
    loadUser() {
      if (this.item.id) {
        let params = [{ param: "idChapter", value: this.item.id }];
        this.service.read(params).then((data) => {
          this.users = data;
        });
      }
    },
    saveUser() {
      if (this.item.id) {
        let params = [{ param: "idChapter", value: this.item.id }];
        this.service
          .updateParams(this.users, params)
          .then(() => {
            this.$successToast();
          })
          .catch(() => {
            this.$errorToast();
          });
      }
    },
    saveItem() {
      if (this.video_cover) this.item.video_cover = this.video_cover;
      this.$emit("save-details", this.item);
    },
  },
  computed: {
    tmpDatetime: {
      get: function () {
        let datetime = this.item["data_ora_sessione_live"];
        // let dateTimeFormatted = new Date(datetime)
        // return dateTimeFormatted
        // if (datetime[12] == ':') {
        //   datetime = datetime.substring(0, 10) + ' 0' + datetime.substring(11, 18)
        // }
        return datetime.replace("T", " ");
      },
      set: function (newValue) {
        this.item["data_ora_sessione_live"] = newValue.replace(" ", "T");
      },
    },
    video_cover_url() {
      if (this.video_cover) {
        return URL.createObjectURL(this.video_cover) || "";
      } else {
        return "";
      }
    },
  },
  watch: {
    details: {
      handler(newVal) {
        this.item = newVal;
      },
      deep: true,
    },
  },
};
</script>
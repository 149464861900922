<template>
  <div class="chapter-question-editor">
    <div class="w-100 d-flex justify-content-end mb-4">
      <view-lang-select
        v-model="view_lang"
        :options="languages"
        class="float-right"
      />
    </div>
    <draggable
      v-if="item.questions.length"
      tag="ul"
      :list="item.questions"
      class="list-group"
      handle=".chapter-handle"
    >
      <li
        class="
          list-group-item
          p-0
          border-0
          bg-gray
          mb-3
          d-flex
          align-items-center
        "
        v-for="(element, position) in item.questions"
        :key="element.position"
      >
        <template v-if="!editing_questions.includes(element.id)">
          <div class="d-flex w-100 align-items-center px-3 py-2">
            <img v-if="element.lastUrl" :src="element.lastUrl" />
            <strong class="mr-2">{{ position + 1 }}°</strong>
            <strong class="ml-3 mr-auto">
              {{ element.question[view_lang] }}
            </strong>
          </div>
          <span class="answers-number text-muted">
            {{ element.answers.length }}
            <template v-if="element.answers.length == 1">answer</template>
            <template v-else>answers</template>
          </span>
          <div
            class="
              tools-box
              align-self-stretch
              ml-auto
              d-flex
              align-items-center
            "
          >
            <b-icon icon="arrows-move" class="chapter-handle pointer"></b-icon>
            <b-icon
              icon="gear-fill"
              class="pointer"
              @click="editing_questions.push(element.id)"
            ></b-icon>
            <b-icon
              icon="trash-fill"
              class="pointer"
              @click="deleteQuestion(element.id)"
            ></b-icon>
          </div>
          <span class="text">{{ element.name }} </span>
        </template>
        <template v-else>
          <question-editor
            :question="element"
            :position="position"
            :default_lang="default_lang.value"
            :languages="languages"
            @close-question-editor="removeQuestionFromEditing"
            @update-question="updateQuestion"
          />
        </template>
      </li>
    </draggable>
    <div v-else class="mt-2 mb-3">No questions present</div>
    <b-button
      squared
      size="lg"
      class="button-dashed big-button w-100 mt-2"
      @click="addNewQuestion"
      >Add Question</b-button
    >
    <div class="mt-5 p-4 bg-gray">
      <b-form-checkbox v-model="item.enable_max_time">
        <div>
          Definisci tempo massimo per ogni risposta da dare
          <InfoPopover
            class="ml-1"
            text="Set the countdown timer for each question"
          />
        </div>
        <b-input-group append="secondi" class="mt-3">
          <b-form-input v-model="item.max_time"></b-form-input>
        </b-input-group>
        <b-form-checkbox v-model="item.enable_chiedi_aiuto" class="mt-3" switch>
          Attiva la funzione "Chiedi aiuto"
          <InfoPopover class="ml-1" text="Enable the 'Help' function" />
        </b-form-checkbox>
      </b-form-checkbox>
      <b-form-checkbox v-model="item.unlock_next" class="mt-3">
        <div>
          Unlock next content at: [%survey correctness]
          <InfoPopover
            class="ml-1"
            text="Enable next content only when user reach this percentage of correct answers"
          />
          <b-input-group append="%" class="mt-3">
            <b-form-input v-model="item.unlock_next_perc"></b-form-input>
          </b-input-group>
        </div>
      </b-form-checkbox>
      <b-form-checkbox v-model="item.enable_morphcast" class="mt-3" disabled>
        <div>
          <b-icon-camera-video-fill />
          Attiva funzione Morphcast Facs (analisi emozioni)
          <InfoPopover class="ml-1" text="Unabled..." />
        </div>
      </b-form-checkbox>

      <div class="mt-5">
        <b-form-group
          label="Number of action for a correct answer"
          class="mt-4"
        >
          <b-form-input v-model="item.numeroAzioniRispostaCorretta" />
        </b-form-group>
        <b-form-group label="Action value" class="mt-4">
          <b-form-input v-model="item.valoreAzioneDefault" />
        </b-form-group>
      </div>
    </div>
    <div class="mt-5 mb-5">
      <b-button
        v-if="isLoading"
        squared
        variant="primary"
        size="lg"
        class="big-button"
        disabled
        ><b-spinner small type="grow"></b-spinner> Loading...</b-button
      >
      <b-button
        v-else
        squared
        variant="primary"
        size="lg"
        class="big-button"
        @click="saveItem"
        >Save Questions and Settings</b-button
      >
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import QuestionEditor from "./QuestionEditor.vue";
import AxiosService from "@/axiosServices/AxiosService";
export default {
  props: ["id", "languages", "default_lang"],
  components: {
    draggable,
    QuestionEditor,
  },
  data() {
    return {
      item: {
        enable_max_time: false,
        max_time: 0,
        enable_chiedi_aiuto: true,
        unlock_next: false,
        unlock_next_perc: 0,
        enable_morphcast: false,
        questions: [],
        numeroAzioniRispostaCorretta: 0,
        valoreAzioneDefault: 0,
      },
      editing_questions: [],
      view_lang: null,
      isLoading: false,
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("LearningQuiz");
    this.view_lang = this.default_lang.value;
  },
  mounted() {
    this.loadFromApi();
  },
  methods: {
    loadFromApi() {
      if (this.id) {
        this.service.readById(this.id).then((res) => {
          this.item = res;
        });
      }
    },
    removeQuestionFromEditing(id) {
      this.editing_questions.splice(
        this.editing_questions.findIndex((e) => e === id),
        1
      );
    },
    addNewQuestion() {
      let id = uuid();
      this.item.questions.push({
        id: id,
        question: {},
        answers: [
          {
            id: uuid(),
            answer: {},
            correct: false,
          },
          {
            id: uuid(),
            answer: {},
            correct: false,
          },
          {
            id: uuid(),
            answer: {},
            correct: true,
          },
        ],
      });
      this.editing_questions.push(id);
    },
    updateQuestion(updated_question) {
      const id = updated_question.id;
      const i = this.item.questions.findIndex((obj) => obj.id == id);
      this.item.questions[i] = updated_question;
      this.removeQuestionFromEditing(id);
    },
    saveItem() {
      this.isLoading = true;
      let item = this.item;

      console.log("ITEM AT SAVE ", item);

      // const uploadFunction = async (item) => {
      //   const formData = new FormData();
      //   // let blob = await fetch(item.fileUrl).then((r) => r.blob());
      //   formData.append("file", item.fileUrl);
      //   const result = await this.service
      //     .uploadFile(formData)
      //     .then((r) => {
      //       if (r.data) {
      //         item.image_url = this.$driveAddress + r.data;
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     })
      //     .catch(() => false);
      //   return result;
      // };

      let promises = [];
      this.item.questions.forEach((question) => {
        if (question.fileUrl) {
          question.image_url = question.fileUrl;
          // promises.push(uploadFunction(question));
        }
        question.answers.forEach((answer) => {
          if (answer.fileUrl) {
            answer.image_url = answer.fileUrl;
            // promises.push(uploadFunction(answer));
          }
        });
      });

      Promise.all(promises).finally(() => {
        if (item.id) {
          this.service
            .update(item)
            .then((response) => {
              if (response.id == item.id) {
                this.loadFromApi();
                this.$successToast();
                this.$emit("update-questions-number", item.questions.length);
              } else {
                this.$errorToast();
                return false;
              }
            })
            .catch(() => {
              this.$errorToast();
              return false;
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.service
            .create(item)
            .then((response) => {
              if (response.id) {
                item.id = response.id;
                this.$emit("add-survey", item.id);
                this.$emit("update-questions-number", item.questions.length);
                this.loadFromApi();
                this.$successToast("Survey created");
              } else {
                this.$errorToast("Survey not created");
                return false;
              }
            })
            .catch(() => {
              this.$errorToast("Survey not created");
              return false;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    deleteQuestion(id) {
      this.item.questions = this.item.questions.filter((x) => x.id !== id);
    },
  },
  watch: {
    id() {
      this.loadFromApi();
    },
  },
};
</script>
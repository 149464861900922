<template>
  <div>
    <div v-show="!items.length" class="mb-4">No chapters inserted</div>
    <draggable
      tag="ul"
      :list="items"
      class="list-group"
      handle=".chapter-handle"
      @change="postNewPosition"
    >
      <li
        class="
          list-group-item
          p-0
          chapter-item
          nestable-whappy-item
          d-flex
          align-items-center
        "
        v-for="(element, position) in items"
        :key="element.position"
        :class="{ locked: element.lock }"
      >
        <div class="d-flex w-100 align-items-center p-2">
          <span class="circle-text">{{ position + 1 }}</span>
          <span class="ml-3">
            <b-icon :icon="getIconName(element['id_tipo'])" />
          </span>
          <strong class="ml-3 mr-auto">
            {{ element.title[lang] }}
          </strong>
          <b-form-checkbox
            v-model="element.attivo"
            name="check-button"
            class="chapter-switch"
            @change="updateState(element)"
            switch
          ></b-form-checkbox>
          <span class="chapter-questions text-muted">
            {{ element.numeroDomande }}
            <template v-if="element.numeroDomande == 1">Question</template>
            <template v-else>Questions</template>
          </span>
        </div>
        <div
          class="tools-box align-self-stretch ml-auto d-flex align-items-center"
        >
          <b-icon icon="arrows-move" class="chapter-handle pointer"></b-icon>
          <b-icon
            icon="gear-fill"
            class="pointer"
            @click="$emit('chapter-edit', element.id)"
          ></b-icon>
          <b-icon
            icon="trash-fill"
            class="pointer"
            @click="
              toDeleteId = element.id;
              showDeleteModal = true;
            "
          ></b-icon>
        </div>
      </li>
    </draggable>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="toDeleteId = null"
      @ok="deleteItem(toDeleteId)"
      >Do you really want to delete this chapter?</b-modal
    >

    <b-button
      squared
      size="lg"
      class="button-dashed big-button w-100"
      @click="$emit('chapter-edit', null)"
      >Add Chapter</b-button
    >
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AxiosService from "@/axiosServices/AxiosService";
export default {
  components: {
    draggable,
  },
  props: ["idCategory", "lang"],
  data() {
    return {
      items: [],
      dragging: false,
      toDeleteId: null,
      showDeleteModal: false,
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("LearningChapters");
  },
  mounted() {
    let params = [{ param: "idCategoria", value: this.idCategory }];
    this.service.read(params).then((data) => {
      this.items = data;
      this.postNewPositionWOAlert();
    });
  },
  methods: {
    getIconName(type) {
      if (type == 1) return "camera-reels-fill";
      if (type == 2) return "file-earmark-font-fill";
      return "x-circle";
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    postNewPositionWOAlert() {
      console.log("setting ordine capitoli");
      let newOrder = [];
      for (let i = 0; i < this.items.length; i++) {
        newOrder.push({ ID: this.items[i].id, Ordine: i });
      }
      console.log(newOrder);
      this.service
        .updateOrder(newOrder, "Chapter")
        .then((res) => {
          console.log(res);
        })
        .catch(() => {
          return false;
        });
    },
    postNewPosition() {
      let newOrder = [];
      for (let i = 0; i < this.items.length; i++) {
        newOrder.push({ ID: this.items[i].id, Ordine: i });
      }
      this.service
        .updateOrder(newOrder, "Chapter")
        .then(() => {
          this.$successToast();
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    updateState(item) {
      this.service.update(item).then((response) => {
        if (response.id == item.id) {
          this.$successToast();
        } else {
          this.$errorToast();
          item.attivo = !item.attivo;
          return false;
        }
      });
    },
  },
};
</script>
<template>
  <div>
    <BoxOptions class="mt-2 mb-5" v-model="item.id_tipo" :options="option_items" />
    <b-tabs content-class="mt-3 p-2 pt-3" justified>
      <b-tab title="1. Details" active>
        <video-details v-if="item.id_tipo == 1" :details="item" :isLoading="isSaving" :languages="languages"
          :default_lang="default_lang" @save-details="saveDetails" />
        <documenti-details v-if="item.id_tipo == 2" :details="item" :isLoading="isSaving" :languages="languages"
          :default_lang="default_lang" @save-details="saveDetails" />
      </b-tab>
      <b-tab :title="questionTabTitle()" :disabled="!id">
        <chapter-questions-editor :id="item.id_survey" :languages="languages" :default_lang="default_lang"
          @add-survey="addSurvey" @update-questions-number="updateQuestionsNumber" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ChapterQuestionsEditor from "./ChapterQuestionsEditor.vue";
import AxiosService from "@/axiosServices/AxiosService";
import VideoDetails from "./VideoDetails.vue";
import DocumentiDetails from "./DocumentiDetails.vue";
import { dateTimeNow } from "@/utils/DatetimeUtils.js";
export default {
  components: {
    ChapterQuestionsEditor,
    VideoDetails,
    DocumentiDetails,
  },
  props: ["id", "idCategory", "languages", "default_lang"],
  data() {
    return {
      option_items: [
        {
          value: 1,
          text: "Video",
          icon: "shop",
          description: "Select video content to add to this chapter",
        },
        {
          value: 2,
          text: "Documenti",
          icon: "pen",
          description: "Select documents to add to this chapter",
        },
      ],

      item: {},
      fileUrl: this.$driveAddress,

      numeroDomandeAggiornato: null,
      isSaving: false,
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("LearningChapters");

    this.item = {
      id_tipo: 1,
      id_categoria: 0,
      id_survey: 0,
      is_free_content: true,
      sessione_live: true,
      video_type_contenuto_formativo: 1,
      link_contenuto_formativo: "",
      cover_contenuto_formativo: "",
      embed_contenuto_formativo: "",
      link_sessione_live: "",
      data_ora_sessione_live: dateTimeNow(),
      recorded_video: true,
      recorded_video_link: "",
      unlock_perc: 0,
      order: 0,
      title: {},
      description: {},
      documents: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.id) {
        this.service.readById(this.id).then((res) => {
          this.item = res;
          //fix temporaneo
          if (!this.item.video_type_contenuto_formativo)
            this.$set(this.item, "video_type_contenuto_formativo", 1);
          if (!this.item.cover_contenuto_formativo)
            this.$set(this.item, "cover_contenuto_formativo", "");
          if (!this.item.embed_contenuto_formativo)
            this.$set(this.item, "embed_contenuto_formativo", "");
        });
      }
    },
    questionTabTitle() {
      if (this.id) {
        const nd = this.numeroDomandeAggiornato || this.item.numeroDomande;
        const q = nd == 1 ? " question" : " questions";
        return "2. Questions (" + nd + q + ")";
      } else {
        return "2. Questions (save chapter first)";
      }
    },
    saveDetails(item) {
      this.isSaving = true;

      const validationObject = this.$validate("learning_chapter", item);
      if (!validationObject.validation) {
        this.isSaving = false;
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      let params = [{ param: "idCategory", value: this.idCategory }];

      let newDocuments = [];
      let promises = [];

      const uploadDocument = async (formData) => {
        const result = await this.service
          .uploadFile(formData)
          .then((r) => {
            if (r.data) {
              newDocuments.push({
                url: this.fileUrl + r.data,
                nome: r.data.substring(37),
              });
              return true;
            } else {
              return false;
            }
          })
          .catch(() => false);
        return result;
      };

      const uploadVideoCover = async (formData, item) => {
        const result = await this.service
          .uploadFile(formData)
          .then((r) => {
            if (r.data) {
              item.cover_contenuto_formativo = this.fileUrl + r.data;
              delete item.video_cover;
              return true;
            } else {
              return false;
            }
          })
          .catch(() => false);
        return result;
      };

      this.item.documents.forEach((element) => {
        if (element.toUpload) {
          const formData = new FormData();
          formData.append("file", element.file);
          promises.push(uploadDocument(formData));
        } else if (!element.toDelete) {
          newDocuments.push(element);
        }
      });

      if (item.video_cover) {
        const formData = new FormData();
        formData.append("pic", item.video_cover);
        promises.push(uploadVideoCover(formData, item));
      }

      Promise.all(promises).finally(() => {
        item.documents = newDocuments;
        if (item.id) {
          this.service
            .update(item)
            .then((response) => {
              if (response.id == item.id) {
                this.item = item;
                this.$successToast();
              } else {
                this.$errorToast();
                return false;
              }
            })
            .catch(() => {
              this.$errorToast();
              return false;
            })
            .finally(() => {
              this.isSaving = false;
            });
        } else {
          this.service
            .create(item, params)
            .then((response) => {
              if (response.id) {
                item.id = response.id;
                this.item = item;
                this.$emit("chapter-edit", item.id);
                this.$successToast();
              } else {
                this.$errorToast();
                return false;
              }
            })
            .catch(() => {
              this.$errorToast();
              return false;
            })
            .finally(() => {
              this.isSaving = false;
            });
        }
      });
    },
    addSurvey(idSurvey) {
      this.item.id_survey = idSurvey;
      let item = this.item;
      this.service
        .update(item)
        .then((response) => {
          if (response.id == item.id) {
            this.$successToast("Survey associated to chapter");
          } else {
            this.$errorToast("Survey not associated to chapter");
            return false;
          }
        })
        .catch(() => {
          this.$errorToast("Survey not associated to chapter");
          return false;
        });
    },
    updateQuestionsNumber(n) {
      this.numeroDomandeAggiornato = n;
    },
  },
  watch: {
    id: function () {
      this.loadData();
    },
  },
};
</script>